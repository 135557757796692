<template>
  <div class="card" :class="{'is-shadowless': !isModal}" id="modalUpdateHistory">
    <div class="card-content">
      <header class="is-flex is-justify-content-space-between is-align-items-center mb-2">
        <h2 class="is-size-6 has-text-success has-text-weight-bold mr-3" v-if="defaultValue.type === 'stored'">
          Update {{ $t('warehouse.add_item') }}
        </h2>
        <h2 class="is-size-6 has-text-danger has-text-weight-bold mr-3" v-else-if="defaultValue.type === 'removed'">
          Update {{ $t('warehouse.remove_item') }}
        </h2>
        <h2 class="is-size-6 has-text-success has-text-weight-bold mr-3" v-else-if="defaultValue.type === 'used'">
          Update {{ $t('warehouse.use_item') }}
        </h2>

        <div @click="$emit('close')" v-if="isModal">
          <b-icon class="cursor-pointer" icon="close-thick"/>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form-update-history">
          <p v-if="defaultValue.type === 'used'">{{ $t('warehouse.use_item_desc') }}</p>
          <p v-else-if="defaultValue.type === 'stored'">{{ $t('warehouse.add_item_desc') }}</p>
          <p v-else-if="defaultValue.type === 'removed'">{{ $t('warehouse.remove_item_desc') }}</p>
          <form class="mt-3" @submit.prevent="handleSubmit">
            <ValidationProvider :name="$t('warehouse.qty')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('warehouse.qty')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <p class="control w-100">
                  <b-input type="number" id="qty" step="any" v-model.number="form.qty"
                           placeholder="0.0"></b-input>
                </p>
                <p class="control">
                  <b-button type="is-text" class="btn-placeholder">Kg</b-button>
                </p>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datetimepicker
                    :placeholder="$t('date')"
                    icon="calendar-text"
                    locale="id-ID"
                    v-model="form.date">
                </b-datetimepicker>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('desc')" v-slot="{ errors }">
              <b-field :label="$t('desc')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="text" id="description" v-model="form.desc"
                         :placeholder="$t('desc')"></b-input>
              </b-field>
            </ValidationProvider>
          </form>
        </ValidationObserver>
      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-success" class="ml-2" @click="handleSubmit">Update</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdateHistory",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object
    },
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.form.block && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.form.block.block_id && e.status !== 0)
      else return []
    },
  },
  created() {
    this.resetForm()
  },
  data: () => {
    return {
      form: {},
    }
  },
  methods: {
    async handleSubmit() {
      this.$loading()

      let payload = {
        warehouse_id: this.defaultValue.warehouse_id,
        used_sub_id: this.defaultValue.used_sub_id,
        product_name: this.defaultValue.product_name,
        unit: this.defaultValue.unit,
        qty: this.form.qty,
        desc: this.form.desc || '',
        created_at: this.$timestamp(this.form.date),
      }

      if (this.defaultValue.type === 'stored') await this.$store.dispatch('farm/updateRestockItem', payload)
      else if (this.defaultValue.type === 'removed') await this.$store.dispatch('farm/updateRemoveItem', payload)
      else if (this.defaultValue.type === 'used') await this.$store.dispatch('farm/updateUseItem', payload)

      this.$loading(false)
      this.$emit('close')
      this.$emit('submit')
      this.$store.dispatch('farm/refreshWarehouse')
    },

    resetForm() {
      this.form = {}
      if (this.defaultValue) {
        this.form.qty = this.defaultValue.qty
        this.form.date = new Date(this.defaultValue.created_at)
        this.form.desc = this.defaultValue.desc
      }
      if (this.$refs['form-update-history'])
      this.$refs['form-update-history'].reset()
    },

    handleSelectAllPonds() {
      if (this.to_all_ponds) {
        this.form.ponds = this.filteredPonds.filter(e => e)
      } else {
        this.form.ponds = []
      }
    },
  }
}
</script>

<style scoped lang="scss">
.card#modalUpdateHistory {
  width: 450px !important;
  max-width: 100%;
}
</style>

